import React from 'react';

import ContentLoader from 'react-content-loader';

const REPEAT_COUNT = 1;

export default () => {
  return (
    <div>
      <ul>
        {[...Array(REPEAT_COUNT)].map((item, index) => (
          <li key={index} style={{ marginBottom: '2rem' }}>
            <ContentLoader
              speed={2}
              viewBox="0 0 500 56"
              backgroundColor="#f3f3f3"
              foregroundColor="#ecebeb"
            >
              <rect x="0" y="0" width="85" height="56" />
              <rect x="90" y="0" width="140" height="12" />
              <rect x="90" y="23" width="75" height="8" />
              <rect x="90" y="34" width="120" height="8" />
            </ContentLoader>
          </li>
        ))}
      </ul>
    </div>
  );
};
