import React, { Fragment } from 'react';
import { navigate } from 'gatsby';

import Button from '../../atoms/Button/button';
import BuyerCartContentLoader from './buyer-cart-loader';
import CartListItem from '../../molecules/CartItem/cart-item';

import { INVENTORIES } from '../../../constants/routes';

import './buyer-cart.scss';

export default ({ firebase, authUser, loading, cart }) => {
  const navigateToInventories = () => {
    firebase.useAnalytics('explore_assets_clicked', { user: authUser.email });
    return navigate(INVENTORIES);
  };

  if (loading) return <BuyerCartContentLoader />;

  return (
    <div className="cart-container has-text-centered">
      {cart.length ? (
        cart.map((cartItem) => (
          <CartListItem
            key={`
                ${cartItem.inventory.internalId}
                -${cartItem.requestStartDate}
                -${cartItem.numberOfDays}
              `}
            cartItem={cartItem}
          />
        ))
      ) : (
        <Fragment>
          <div className="column">
            <p className="has-text-weight-bold">Your cart is empty for now!</p>
          </div>
          <div className="column">
            <Button
              text="Explore Assets"
              className="button is-primary is-link"
              onClick={navigateToInventories}
            />
          </div>
        </Fragment>
      )}
    </div>
  );
};
