import React from 'react';

import ContentLoader from 'react-content-loader';

export default () => {
  return (
    <div>
      <ul>
        {[...Array(1)].map((item, index) => (
          <li key={index}>
            <ContentLoader
              width={'100%'}
              height={'50%'}
              viewBox="0 0 140 17"
              backgroundColor="#f3f3f3"
              foregroundColor="#ecebeb"
            >
              <rect x="0" y="0" width="55" height="5" />
              <rect x="120" y="0" width="50" height="5" />
              <rect x="0" y="7" width="55" height="5" />
            </ContentLoader>
          </li>
        ))}
      </ul>
    </div>
  );
};
