import React, { useContext } from 'react';
import * as NumberAbbrevation from 'number-abbreviate';
import { withFirebase } from '../../../utils/Firebase';

import Button from '../../atoms/Button/button';
import LabeledPin from '../LabeledPin/labeled-pin';

import { assetTypes } from '../../../models/assetTypes';

import PlaceholderImage from '../../../assets/inventory-placeholder.png';

import { AuthUserContext } from '../../../utils/Session';

import './cart-item.scss';

const NumAbbr = new NumberAbbrevation(['K', 'M', 'B', 'T']);

const CartItem = ({ cartItem, firebase }) => {
  const authUser = useContext(AuthUserContext);

  const removeCartItem = () => {
    if (authUser) {
      return firebase.removeFromCart(authUser, cartItem);
    }
  };
  return (
    <article className="media">
      <figure className="media-left">
        <p>
          <img
            src={
              cartItem.inventory.thumbnail
                ? cartItem.inventory.thumbnail
                : PlaceholderImage
            }
            alt="thumbnail_image"
            className="inventory-thumbnail"
          />
        </p>
      </figure>
      <div className="media-content">
        <div className="content">
          <div className="cart-item-title">
            {assetTypes[cartItem.inventory.type].displayText}
            {cartItem.inventory.address && ` - ${cartItem.inventory.address}`}
          </div>
          <div className="cart-item-content">
            {cartItem.inventory.pricing && cartItem.inventory.pricing.dailyRate
              ? `$${cartItem.inventory.pricing.dailyRate} / Day`
              : null}
          </div>
          {cartItem.inventory.averageDailyImpressions ? (
            <div className="cart-item-content">
              {`${NumAbbr.abbreviate(
                cartItem.inventory.averageDailyImpressions,
                1,
              )} Impressions per Day`}
            </div>
          ) : null}
        </div>
      </div>
      <figure className="media-right">
        <div className="labeled-pin">
          <LabeledPin type={cartItem.inventory.type} />
        </div>
        <div className="cart-remove-container">
          <Button
            text="Remove"
            className="btn-danger"
            onClick={removeCartItem}
          />
        </div>
      </figure>
    </article>
  );
};

export default withFirebase(CartItem);
