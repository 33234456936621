import React from 'react';
import { compose } from 'recompose';

import { withAuthorization } from '../utils/Session';

import Layout from '../utils/layout';
import Cart from '../components/scenes/Cart/cart';

const condition = (authUser) => !!authUser;

const CartPage = compose(withAuthorization(condition))(Cart);

export default () => (
  <Layout>
    <CartPage />
  </Layout>
);
