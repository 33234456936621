import React from 'react';
import classNames from 'classnames';
import { Link } from 'gatsby';

import { INVENTORIES } from '../../../constants/routes';
import './request-to-book-modal.scss';

export default ({ modalIsActivated }) => {
  return (
    <div
      className={classNames('modal', {
        'is-active': modalIsActivated,
      })}
    >
      <div className="modal-background" />
      <div className="modal-content rtb-modal">
        <p className="rtb-modal__content-paragraph">
          Thanks for booking through Adsight. A dedicated account executive will
          be in touch to help coordinate set up, artwork alignment, and much
          more.
        </p>
        <p className="rtb-modal__content-paragraph">
          Meanwhile, we're here for you at every step of the way. Feel free to
          contact us through support@adsight.ca if you have any questions or
          concerns.
        </p>
        <p className="rtb-modal__content-action">
          <Link to={INVENTORIES} className="btn-primary">
            Finish & Return Home
          </Link>
        </p>
      </div>
    </div>
  );
};
