import React from 'react';
import MapMarkerPin from '../../atoms/MapMarkerPin/map-marker-pin';

import assetTypes from '../../../models/assetTypes';
import './labeled-pin.scss';

export default ({ type, price, size }) => {
  const assetType = assetTypes[type] || assetTypes['other'];
  return (
    <div className="labeled-pin">
      <div className="labeled-pin__left">
        <MapMarkerPin inputColor={assetType.selector} size={size} />
      </div>
      <div className="labeled-pin__right">
        <p className="pin-title">
          {price ? `from $${price} / day` : assetType.displayText}
        </p>
      </div>
    </div>
  );
};
