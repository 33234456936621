import React from 'react';

import { AuthUserContext } from '../../../utils/Session';

import DashboardTemplate from '../../organisms/DashboardTemplate/dashboard-template';
import ResponseHandler from '../../molecules/ResponseHandler/response-handler';

import BuyerCart from '../../organisms/BuyerCart/buyer-cart';
import ImpressionsCount from '../../molecules/ImpressionsCount/impressions-count';
import RequestToBook from '../../organisms/RequestToBook/request-to-book';

import './cart.scss';

class Cart extends React.Component {
  _initFirebase = false;

  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      buyerInfo: undefined,
      response: undefined,
    };

    this.setBuyerInfo = this.setBuyerInfo.bind(this);
    this.getBuyerInfo = this.getBuyerInfo.bind(this);
    this.createMessage = this.createMessage.bind(this);
    this.deleteMessage = this.deleteMessage.bind(this);
  }

  setBuyerInfo(buyerInfo) {
    return this.setState({ buyerInfo });
  }

  getBuyerInfo() {
    return (
      this.context && this.props.firebase.buyer(this.context, this.setBuyerInfo)
    );
  }

  createMessage({ type, title, message, linkPath, linkText }) {
    return this.setState({
      response: { type, title, message, linkPath, linkText },
    });
  }

  deleteMessage() {
    this.setState({ response: undefined });
  }

  componentDidMount() {
    if (this.props.firebase && !this._initFirebase) {
      this._initFirebase = true;
      this.unsubscribe = this.getBuyerInfo();
    }
  }

  componentDidUpdate() {
    if (this.props.firebase && !this._initFirebase) {
      this._initFirebase = true;
      this.unsubscribe = this.getBuyerInfo();
    }
    if (this.state.buyerInfo && this.state.loading)
      this.setState({ loading: false });
  }

  componentWillUnmount() {
    if (this.unsubscribe) this.unsubscribe();
  }

  render() {
    const { loading, buyerInfo, response } = this.state;
    const { firebase } = this.props;
    return (
      <DashboardTemplate>
        {response && (
          <ResponseHandler
            response={response}
            deleteMessage={this.deleteMessage}
          />
        )}
        <div className="columns">
          <div className="column is-three-fifths">
            <BuyerCart
              firebase={firebase}
              authUser={this.context}
              loading={loading}
              cart={buyerInfo && buyerInfo.cart}
            />
            <ImpressionsCount cart={buyerInfo && buyerInfo.cart} />
            <div>
              <div className="sc-container">
                <h2 className="sc-container__title">Advertising Standards</h2>

                <p className="sc-container__parapgraph">
                  All placements on Adsight must align fully with the Canadian
                  Code of Advertising Standards, which sets the criteria for
                  acceptable advertising in Canada. Created by the advertising
                  industry in 1963 to promote the professional practice of
                  advertising, the Code is the cornerstone of advertising
                  self-regulation in Canada. Administered by Ad Standards, the
                  Code is regularly updated to ensure it is current and
                  contemporary – keeping pace with consumer and societal
                  expectations. It contains 14 provisions that set the criteria
                  for acceptable advertising that is truthful, fair and
                  accurate.
                </p>
                <a
                  href="https://adstandards.ca/code/the-code-online/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Review Advertising Code
                </a>
              </div>
            </div>
          </div>
          <div className="column is-two-fifths">
            <RequestToBook
              loading={loading}
              buyerInfo={buyerInfo && buyerInfo}
              firebase={firebase}
              createMessage={this.createMessage}
            />
          </div>
        </div>
      </DashboardTemplate>
    );
  }
}

Cart.contextType = AuthUserContext;

export default Cart;
