import * as _ from 'lodash';

export const grpCalculator = (inventory, dateRange) => {
  const dailyImpressions = inventory.averageDailyImpressions
    ? parseInt(inventory.averageDailyImpressions)
    : 0;

  const marketPopulation =
    inventory.marketInfo &&
    inventory.marketInfo.population &&
    inventory.marketInfo.population.total
      ? parseInt(inventory.marketInfo.population.total)
      : 0;

  if (!dailyImpressions || !marketPopulation) return 0;
  else {
    return _.round((dailyImpressions / marketPopulation) * 100 * dateRange, 2);
  }
};
