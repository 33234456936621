import React, { useRef } from 'react';
import classNames from 'classnames';

import './map-marker-pin.scss';

const MapMarkerPin = ({
  inputColor,
  size,
  onClick = undefined,
  isHovered,
  isSelected,
  ...props
}) => {
  const iconSize = size ? size : '';

  // dynamic manipulation of parent DOM class for bidirectional binding & z-index manipulation
  const markerIcon = useRef(null);
  if (markerIcon.current) {
    const parentClassList = markerIcon.current.parentElement.classList;
    isSelected || isHovered
      ? parentClassList.add('front')
      : parentClassList.remove('front');
  }

  return (
    <i
      {...props}
      className={classNames(
        'fas fa-map-marker-alt',
        'map-marker-pin',
        `map-marker-pin__${inputColor}`,
        iconSize,
        {
          'map-marker-pin__clickable': onClick,
          selected: isSelected,
          hovered: isHovered,
        },
        props.className,
      )}
      onClick={onClick}
      ref={markerIcon}
    ></i>
  );
};

export default MapMarkerPin;
