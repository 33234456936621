import React, { useEffect } from 'react';
import { Link } from 'gatsby';
import classNames from 'classnames';

import './response-handler.scss';

const responseTypes = {
  error: 'is-danger',
  success: 'is-success',
  warning: 'is-warning',
  info: 'is-info',
};

export default ({ response, deleteMessage }) => {
  useEffect(() => {
    const deleteResponseDelay = response
      ? setTimeout(deleteMessage, 10000)
      : undefined;
    return () => {
      deleteResponseDelay && clearTimeout(deleteResponseDelay);
    };
  }, [response]);
  return (
    <div className="response-handler-container">
      <article className={classNames('message', responseTypes[response.type])}>
        <div className="message-header">
          <p>{response.title}</p>
          <button
            className="delete"
            aria-label="delete"
            onClick={deleteMessage}
          />
        </div>
        <div className="message-body">
          {response.message}
          <p className="response-handler-container__action">
            {response.linkPath && response.linkText && (
              <Link style={{ textDecoration: 'none' }} to={response.linkPath}>
                {response.linkText}
              </Link>
            )}
          </p>
        </div>
      </article>
    </div>
  );
};
