import React, { Fragment, useState, useEffect } from 'react';
import * as NumberAbbrevation from 'number-abbreviate';

import { cpmCalculator } from '../../../utils/helpers/cpm';
import { grpCalculator } from '../../../utils/helpers/grp';

import './impressions-count.scss';

const NumAbbr = new NumberAbbrevation(['K', 'M', 'B', 'T']);

const grpDefinition =
  'Gross Rating Points (GRP) are the total number of in-market impressions delivered by an Out of Home (OOH) or outdoor billboard campaign schedule expressed as a percentage of a market population and is a measure of advertising impact. A one rating point represents impressions equal to 1% of the market population.';

export default ({ cart }) => {
  const [totalImpressions, setTotalImpression] = useState(0);
  const [CPM, setCPM] = useState(0);
  const [GRP, setGRP] = useState(0);

  const findTotalImpressionAndGRP = () => {
    let totalCartPrice = 0;
    let totalCartImpressions = 0;

    if (totalImpressions) setTotalImpression(0);
    if (GRP) setGRP(0);
    if (CPM) setCPM(0);

    for (const cartItem of cart) {
      const dateRange = cartItem.numberOfDays;

      const dailyImpressions = cartItem.inventory.averageDailyImpressions
        ? parseInt(cartItem.inventory.averageDailyImpressions)
        : 0;

      const cartItemDailyRate = cartItem.inventory.pricing.dailyRate
        ? cartItem.inventory.pricing.dailyRate
        : 0;

      if (!dailyImpressions) continue;
      else {
        const cartItemTotalImpressions = dailyImpressions * dateRange;

        if (cartItemDailyRate) {
          const cartItemPrice = cartItemDailyRate * dateRange;
          totalCartPrice = totalCartPrice + cartItemPrice;

          totalCartImpressions =
            totalCartImpressions + cartItemTotalImpressions;
        }

        const cartItemGRP = grpCalculator(cartItem.inventory, dateRange);

        setTotalImpression((preValue) => preValue + cartItemTotalImpressions);
        setGRP((preValue) => preValue + cartItemGRP);
      }
    }

    if (totalCartPrice && totalCartImpressions) {
      const tempCPM = cpmCalculator(totalCartPrice, totalCartImpressions);
      setCPM(tempCPM);
    }
  };

  useEffect(() => {
    if (cart) findTotalImpressionAndGRP();
  }, [cart]);

  return totalImpressions ? (
    <div className="impressions-count-container">
      <p>
        This campaign will earn you an estimated total Impressions of{' '}
        <span>{NumAbbr.abbreviate(totalImpressions, 1)}</span>.
      </p>
      <p>
        {GRP ? (
          <Fragment>
            This campaign will earn you an estimated total GRP of{' '}
            <span>{GRP}</span>.
            <span
              className="has-tooltip-multiline"
              data-tooltip={grpDefinition}
            >
              <i className="fas fa-info-circle"></i>
            </span>
          </Fragment>
        ) : null}
      </p>
      <p>
        {CPM ? (
          <Fragment>
            This campaign will earn you an estimated total CPM of{' '}
            <span>{CPM}$</span>.
          </Fragment>
        ) : null}
      </p>
    </div>
  ) : null;
};
