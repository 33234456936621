import React, { useState, useEffect } from 'react';
import * as NumberAbbrevation from 'number-abbreviate';
import RequestToBookContentLoader from './request-to-book-content-loader';
import RequestToBookModal from '../../molecules/RequestToBookModal/request-to-book-modal';
import Button from '../../atoms/Button/button';

import { assetTypes } from '../../../models/assetTypes';

import './request-to-book.scss';

const NumAbbr = new NumberAbbrevation(['K', 'M', 'B', 'T']);

export default ({ loading, buyerInfo, firebase, createMessage }) => {
  const [estimatedTotal, setEstimatedTotal] = useState(0);

  const [requestToBookLoading, setRequestToBookLoading] = useState(false);

  const [modalIsActivated, setModalIsActivated] = useState(false);

  const requestToBook = async () => {
    setRequestToBookLoading(true);
    const response = await firebase.createOrder({ buyerInfo, createMessage });
    if (response) {
      setRequestToBookLoading(false);
      setEstimatedTotal(0);
      setModalIsActivated(true);
    } else {
      setRequestToBookLoading(false);
    }
  };

  useEffect(() => {
    if (buyerInfo && buyerInfo.cart) {
      let tempTotal = 0;
      setEstimatedTotal(0);
      buyerInfo.cart.forEach((element) => {
        const dailyRate =
          element.inventory.pricing && element.inventory.pricing.dailyRate
            ? element.inventory.pricing.dailyRate
            : 0;
        const pricingPerPeriod = dailyRate * element.numberOfDays;
        tempTotal = tempTotal + pricingPerPeriod;
      });
      setEstimatedTotal(tempTotal.toFixed(2));
    }
  }, [buyerInfo]);

  return (
    <div className="rtb-container">
      <div className="rtb-container__title">Your Cart</div>
      <div className="rtb-container__main-content">
        {loading ? (
          <RequestToBookContentLoader />
        ) : (
          buyerInfo.cart.map((cartItem) => (
            <div
              key={`
                  ${cartItem.inventory.internalId}
                  -${cartItem.requestStartDate.toString()}
                  -${cartItem.numberOfDays}
                `}
              className="rtb-container__main-content__item"
            >
              <div className="level" style={{ margin: 0 }}>
                <div className="level-left rtb-container__main-content__item__wrapper">
                  <div className="rtb-container__main-content__item__text has-text-weight-bold">
                    {assetTypes[cartItem.inventory.type].displayText}
                    {cartItem.inventory.address &&
                      ` - ${cartItem.inventory.address}`}
                  </div>
                </div>
                <div className="level-right is-marginless">
                  <div className="rtb-container__main-content__item__text">
                    x {cartItem.numberOfDays} Day(s)
                  </div>
                </div>
              </div>
              {cartItem.inventory.averageDailyImpressions ? (
                <div className="rtb-container__main-content__item__text">
                  Total Impressions:{' '}
                  {NumAbbr.abbreviate(
                    cartItem.inventory.averageDailyImpressions *
                      cartItem.numberOfDays,
                    1,
                  )}
                </div>
              ) : null}
            </div>
          ))
        )}
        <div className="level">
          <div className="level-left">
            <p className="has-text-weight-bold">Estimated Total</p>
          </div>
          <div className="level-right is-marginless">
            <p className="has-text-weight-bold">${estimatedTotal}</p>
          </div>
        </div>
      </div>
      <div className="rtb-container__footer">
        Requesting to book will connect you to the relevant reps. You will have
        a chance to review the final pricing prior to booking.
      </div>
      <div className="rtb-container__footer">* No credit card required</div>
      <div className="rtb-container__action">
        <Button
          text="Request to book"
          className={
            'button is-fullwidth is-success ' +
            { 'is-loading': requestToBookLoading }
          }
          onClick={requestToBook}
          disabled={buyerInfo && buyerInfo.cart && !buyerInfo.cart.length}
        />
      </div>
      <RequestToBookModal modalIsActivated={modalIsActivated} />
    </div>
  );
};
